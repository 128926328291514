<template>

  <b-container>

    <h1>Dog Food Calculator</h1>

    <b-row class="mt-3" v-b-tooltip.hover title="Dog's weight in pounds">
      <b-col sm="3">
        <label for="weight">Weight (lb):</label>
      </b-col>
      <b-col sm="9">
        <b-form-input :id="weight" type="number" v-model="weight" @change="saveToLocalStorage"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-1" v-b-tooltip.hover title="Amount of calories in 1 cup of dog food (varies by brand)">
      <b-col sm="3">
        <label for="calsPerCup">Calories / Cup:</label>
      </b-col>
      <b-col sm="9">
        <b-form-input :id="calsPerCup" type="number" v-model="calsPerCup" @change="saveToLocalStorage"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-b-tooltip.hover title="See chart below">
      <b-col sm="3">
        <label for="multiplyer">Life Stage Multiplier: {{ multiplier }}</label>
      </b-col>
      <b-col sm="9">
        <b-form-input id="multiplyer" v-model="multiplier" @change="multiplierChanged" type="range" min="0" max="4" step="0.1"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col sm="12">
        <label for="lifeStageTable" class="h2">Life Stage</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <small>(Select a row)</small>
      </b-col>
    </b-row>

    <b-row>
      <b-table id="lifeStageTable" striped hover :items="items" :fields="fields"
               @row-clicked="tableRowClicked"></b-table>
    </b-row>
    <hr/>

    <b-row>
      <b-col sm="12">
        <span class="display-3">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"
               viewBox="0 0 59 59"
               height="1em">
            <path d="M58.954 40.109c-.399-4.089-3.452-7.052-7.271-7.052-2.396 0-4.407 1.449-5.684 3.213-1.276-1.764-3.288-3.213-5.684-3.213-3.819 0-6.871 2.963-7.271 7.052-.042.268-.144 1.22.226 2.709.546 2.197 1.801 4.192 3.631 5.77l8.329 7.127c.215.183.489.285.77.285s.555-.102.769-.284l8.331-7.129c1.828-1.575 3.083-3.57 3.629-5.768.37-1.49.267-2.443.225-2.71zm-2.167 2.228c-.445 1.794-1.479 3.432-2.99 4.732L46 53.741l-7.795-6.671c-1.513-1.303-2.547-2.94-2.992-4.734-.302-1.213-.194-1.897-.194-1.897l.016-.105c.245-2.621 2.138-5.275 5.282-5.275 2.189 0 3.974 1.811 4.77 3.605L46 40.723l.914-2.061c.796-1.795 2.58-3.605 4.77-3.605 3.145 0 5.037 2.654 5.295 5.366.001.007.112.692-.192 1.914zM0 3h17v8H0zM34 3h17v8H34zM19 3h13v8H19zM28.744 21.902c-1.503 0-2.782.528-3.837 1.583-1.056 1.056-1.583 2.34-1.583 3.854 0 1.514.527 2.793 1.583 3.837 1.055 1.044 2.34 1.565 3.854 1.565 1.514 0 2.793-.521 3.837-1.565s1.565-2.34 1.565-3.889-.527-2.833-1.583-3.854c-1.054-1.021-2.333-1.531-3.836-1.531z"/>
            <path d="M30.169 42.615a10.53 10.53 0 0 1-2.509.293h-.603v-1.893h.74c.785 0 1.516-.131 2.205-.362.006-.393.035-.709.067-.933a11.035 11.035 0 0 1 2.771-6.287c-1.165.796-2.494 1.201-3.994 1.201-2.1 0-3.86-.711-5.282-2.134-1.423-1.423-2.134-3.149-2.134-5.18s.711-3.756 2.134-5.179c1.422-1.423 3.148-2.134 5.179-2.134s3.757.711 5.18 2.134c1.422 1.423 2.134 3.154 2.134 5.196v3.694a9.847 9.847 0 0 1 4.259-.976c1.994 0 3.983.686 5.684 1.946 1.506-1.116 3.238-1.779 5-1.917V14a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v35a1 1 0 0 0 1 1h33.031c-1.81-1.837-3.072-4.046-3.672-6.459-.081-.327-.14-.632-.19-.926zm-12.111.463h-2V22L11 25v-2l5.058-3h2v23.078z"/>
          </svg>
          Daily Calories: {{ calories().toFixed(0) }}
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <span class="display-3">
          <svg id="dog-food" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px"
               viewBox="0 0 489.108 489.108"
               style="enable-background:new 0 0 489.108 489.108;"
               xml:space="preserve"
               height="1em">
            <g>
              <path d="M488.895,349.458l-35.6-155.711c-0.074-0.322-0.169-0.634-0.276-0.938c-2.71-9.849-15.761-17.291-41.196-23.553
                c-4.838-25.417-27.213-44.697-54.018-44.697c-1.71,0-3.424,0.082-5.136,0.243c-9.346-18.041-28.186-29.743-48.864-29.743
                c-6.311,0-12.58,1.093-18.488,3.197c-8.821-13.709-24.037-22.197-40.762-22.197s-31.94,8.487-40.762,22.197
                c-5.908-2.104-12.177-3.197-18.488-3.197c-20.678,0-39.518,11.703-48.864,29.743c-1.712-0.162-3.426-0.243-5.136-0.243
                c-26.805,0-49.18,19.28-54.018,44.697c-26.029,6.409-39.096,14.05-41.376,24.245c-0.034,0.124-0.076,0.244-0.104,0.371
                L0.214,349.457C0.072,350.078,0,350.714,0,351.352c0,16.291,25.438,30.818,75.605,43.178
                c48.476,11.943,108.477,18.52,168.949,18.52c60.473,0,120.473-6.577,168.949-18.52c50.168-12.36,75.605-26.887,75.605-43.177
                C489.108,350.715,489.037,350.08,488.895,349.458z M412.294,186.939c16.179,4.391,21.832,8.251,23.652,9.917
                c-2.092,1.92-9.26,6.749-31.548,11.909C408.525,202.183,411.238,194.755,412.294,186.939z M131.304,141.558
                c2.799,0,5.626,0.318,8.403,0.944c4.178,0.944,8.403-1.369,9.862-5.395c5.432-14.983,19.792-25.05,35.735-25.05
                c6.366,0,12.664,1.605,18.211,4.642c2.117,1.158,4.626,1.363,6.903,0.562c2.276-0.801,4.105-2.532,5.03-4.761
                c4.9-11.812,16.325-19.443,29.107-19.443s24.206,7.632,29.106,19.443c0.925,2.229,2.754,3.96,5.03,4.761
                c2.277,0.801,4.787,0.596,6.903-0.562c5.547-3.037,11.845-4.642,18.211-4.642c15.943,0,30.303,10.067,35.735,25.05
                c1.459,4.025,5.685,6.341,9.862,5.395c2.777-0.626,5.604-0.944,8.403-0.944c20.953,0,37.431,17.055,38,38
                c0.394,14.497-9.948,27.233-20.027,34.521c-36.225,5.436-82.663,8.422-131.223,8.422c-47.355,0-92.688-2.841-128.503-8.023
                c-13.38-6.24-22.747-20.152-22.747-34.92C93.304,158.605,110.351,141.558,131.304,141.558z M76.811,186.94
                c1.064,7.84,3.802,15.268,7.916,21.829c-22.3-5.161-29.472-9.992-31.564-11.913C54.982,195.19,60.634,191.331,76.811,186.94z
                M405.664,378.936c-46.549,11.037-103.766,17.115-161.109,17.115c-57.343,0-114.56-6.078-161.11-17.115
                c-45.854-10.872-64.007-22.733-66.28-27.342L48.577,214.28c3.222,1.808,6.261,3.032,8.048,3.751
                c10.661,4.292,25.707,8.11,44.719,11.348c38.329,6.527,89.188,10.122,143.211,10.122s104.882-3.595,143.21-10.122
                c19.012-3.238,34.058-7.056,44.719-11.348c1.79-0.721,4.836-1.947,8.065-3.76l31.396,137.322
                C469.673,356.201,451.52,368.063,405.664,378.936z"/>
              <path d="M347.956,246.28c-19.751,0-36.191,14.451-39.304,33.333H180.456c-3.112-18.883-19.553-33.333-39.304-33.333
                c-21.964,0-39.833,17.869-39.833,39.833c0,8.761,2.863,17.151,8.042,24c-5.179,6.849-8.042,15.239-8.042,24
                c0,21.964,17.869,39.833,39.833,39.833c19.751,0,36.191-14.451,39.304-33.333h128.196c3.112,18.883,19.553,33.333,39.304,33.333
                c21.964,0,39.833-17.869,39.833-39.833c0-8.761-2.863-17.151-8.042-24c5.179-6.849,8.042-15.239,8.042-24
                C387.789,264.149,369.92,246.28,347.956,246.28z M362.626,316.623c5.188,4.356,8.163,10.731,8.163,17.491
                c0,12.59-10.243,22.833-22.833,22.833c-12.591,0-22.834-10.243-22.834-22.833c0-0.491,0.02-0.978,0.051-1.461
                c0.149-2.343-0.678-4.644-2.285-6.355c-1.607-1.712-3.85-2.683-6.198-2.683H172.418c-2.348,0-4.591,0.971-6.198,2.683
                c-1.607,1.712-2.434,4.012-2.285,6.355c0.031,0.483,0.051,0.97,0.051,1.461c0,12.59-10.243,22.833-22.834,22.833
                c-12.59,0-22.833-10.243-22.833-22.833c0-6.759,2.975-13.134,8.163-17.491c1.923-1.615,3.034-3.998,3.034-6.509
                c0-2.511-1.11-4.894-3.034-6.509c-5.188-4.356-8.163-10.731-8.163-17.491c0-12.59,10.243-22.833,22.833-22.833
                c12.591,0,22.834,10.243,22.834,22.833c0,0.491-0.02,0.978-0.051,1.461c-0.149,2.343,0.678,4.644,2.285,6.355
                c1.606,1.712,3.85,2.683,6.198,2.683H316.69c2.348,0,4.591-0.971,6.198-2.683c1.607-1.712,2.434-4.012,2.285-6.355
                c-0.031-0.483-0.051-0.97-0.051-1.461c0-12.59,10.243-22.833,22.834-22.833c12.59,0,22.833,10.243,22.833,22.833
                c0,6.759-2.975,13.134-8.163,17.491c-1.923,1.615-3.034,3.998-3.034,6.509C359.593,312.625,360.703,315.008,362.626,316.623z"/>
            </g>
          </svg>
          Cups of Food: {{ cups().toFixed(1) }}
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col sm="12">
      <a href="https://vet.osu.edu/vmc/companion/our-services/nutrition-support-service/basic-calorie-calculator">
        Calculations based research from Ohio State University - Veterinary Medical Center
      </a>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" class="mt-5">
      This calculator provides a rough estimate of your dog's calorie intake.
      Check with your veterinarian if you have any questions.
      </b-col>
    </b-row>

    <b-row class="mb-0">
      <b-col sm="6" v-for="image in images" :key="image.alt">
        <img :src="image.img"
             :alt="`Dog Body Conditions - ${image.alt}`"
             class="condition mt-5"
             copyright="CC BY 4.0 - Perry, LeeAnn & Shmalberg, Justin & Tanprasertsuk, Jirayu & Massey, Dan & Honaker, Ryan & Jha, Aashish. (2020)">
      </b-col>
    </b-row>

    <b-row class="mb-5 mt-1">
      <b-col sm="12">
        <a href="https://www.researchgate.net/publication/340190495_Risk_factors_associated_with_canine_overweightness_and_obesity_in_an_owner-reported_survey"
           title="CC BY 4.0 - Perry, LeeAnn & Shmalberg, Justin & Tanprasertsuk, Jirayu & Massey, Dan & Honaker, Ryan & Jha, Aashish. (2020)">
          Images from: Risk factors associated with canine overweightness and obesity in an owner-reported survey.
        </a>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'Dog Food Calculator',
  data() {
    return {
      multiplier: '1.6',
      weight: '50',
      calsPerCup: '525',
      fields: [
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'value',
          label: 'Value',
        }
      ],
      items: [
        {description: 'Puppy 0-4 months',        value: '3.0',     mult: 3.0, color: 'success',   _rowVariant: ''},
        {description: 'Puppy 4 months to adult', value: '2.0',     mult: 2.0, color: 'secondary', _rowVariant: ''},
        {description: 'Intact Adult',            value: '1.8',     mult: 1.8, color: 'primary',   _rowVariant: ''},
        {description: 'Neutered Adult',          value: '1.6',     mult: 1.6, color: 'primary',   _rowVariant: ''},
        {description: 'Weight gain',             value: '1.2-1.8', mult: 1.5, color: 'primary',   _rowVariant: ''},
        {description: 'Inactive/obese prone',    value: '1.2-1.4', mult: 1.3, color: 'warning',   _rowVariant: ''},
        {description: 'Weight Loss',             value: '1.0',     mult: 1.0, color: 'danger',    _rowVariant: ''},
      ],
      images: {
        underweight: {
          alt: "Underweight",
          img: require('../../public/underweight.jpg')
        },
        ideal: {
          alt: "Ideal",
          img: require('../../public/ideal.jpg')
        },
        overweight: {
          alt: "Overweight",
          img: require('../../public/overweight.jpg')
        },
        veryOverweight: {
          alt: "Very Overweight",
          img: require('../../public/very-overweight.jpg')
        },
        obese: {
          alt: "Obese",
          img: require('../../public/obese.jpg')
        },
        severelyObese: {
          alt: "Severely Obese",
          img: require('../../public/severely-obese.jpg')
        }
      }
    }
  },
  mounted() {
    if (localStorage.multiplier) {
      this.multiplier = localStorage.multiplier
    }
    if (localStorage.weight) {
      this.weight = localStorage.weight
    }
    if (localStorage.calsPerCup) {
      this.calsPerCup = localStorage.calsPerCup
    }
  },
  methods: {
    saveToLocalStorage: function() {
      localStorage.multiplier = this.multiplier
      localStorage.weight = this.weight
      localStorage.calsPerCup = this.calsPerCup
    },
    rer: function () {
      return (Math.pow(this.weight / 2.2, 0.75) * 70);
    },
    calories: function () {
      return this.rer() * this.multiplier;
    },
    cups: function () {
      return this.calories() / this.calsPerCup;
    },
    tableRowClicked: function (row) {
      this.multiplier = row.mult
      this.resetRowColors()
      row._rowVariant = row.color
      this.saveToLocalStorage()
    },
    resetRowColors: function() {
      this.items.forEach(item => {
        item._rowVariant = ''
      })
    },
    selectRow: function(index) {
      this.items[index]._rowVariant = this.items[index].color;
    },
    multiplierChanged: function (mult) {
      console.log("mult", mult)
      this.resetRowColors()

      if (mult <= 1) {
        this.selectRow(6)
      } else if (mult <= 1.4) {
        this.selectRow(5)
      } else if (mult == 1.6) {
        this.selectRow(3)
      } else if (mult == 1.8) {
        this.selectRow(2)
      } else if (mult < 1.8) {
        this.selectRow(4)
      } else if (mult < 3) {
        this.selectRow(1)
      } else {
        this.selectRow(0)
      }

      this.saveToLocalStorage()
    }
  }
}
</script>

<style scoped>

#multiplyer {
  background: linear-gradient(to right,
    var(--danger) 26%,
    var(--warning) 28% 34%,
    var(--primary) 36% 47%,
    var(--secondary) 49% 71%,
    var(--success) 73%);
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.condition {
  width: 100%;
  max-width: 600px;
}

</style>
